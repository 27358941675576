import { useState } from 'react';
import EventsModal from '../EventsModal';
import './events.css';

function EventsCard(props) {
    const { content, title } = props;
    const [open, setOpen] = useState(false);

    // Extrair a primeira imagem e uma parte do texto para o resumo
    const firstImage = content.find(item => item.type === 'image');
    const firstText = content.find(item => item.type === 'text');
    const parsedSummary = firstText ? `${firstText.text.slice(0, 150)}...` : '';
    const firstImageSrc = firstImage ? (Array.isArray(firstImage.src) ? firstImage.src[0] : firstImage.src) : null;

    return (
        <div className="case-card">
            {firstImageSrc && <img src={firstImageSrc} alt={firstImage.alt || 'Imagem do projeto'} />}
            <h3>{title}</h3>
            <p>{parsedSummary}</p>
            <button onClick={() => setOpen(true)}>Saiba mais</button>
            {open && <EventsModal {...props} setOpen={setOpen} />}
        </div>
    );
}

export default EventsCard;
