import { useParams, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import events from '../../data/events';
import EventsModal from '../../components/EventsModal';
import './styles.css';

function EventsLandingPage() {
    const { id } = useParams();
    const history = useHistory();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        // Simulando carregamento dos dados
        const timer = setTimeout(() => {
            const foundEvent = events.find(event => event.id === id);
            if (foundEvent) {
                setEvent(foundEvent);
            } else {
                setError(true);
            }
            setLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, [id]);

    const handleBack = () => {
        history.push('/evento');
    };

    if (loading) {
        return (
            <div className="events-landing-page">
                <div className="loading-container">
                    <p>Carregando evento...</p>
                </div>
            </div>
        );
    }

    if (error || !event) {
        return (
            <div className="events-landing-page">
                <div className="error-container">
                    <h2>Evento não encontrado</h2>
                    <p>O evento que você está procurando não existe ou foi removido.</p>
                    <button className="back-button" onClick={handleBack}>
                        Voltar para lista de eventos
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="events-landing-page">
            <div className="event-header">
                <button className="back-button" onClick={handleBack}>
                    ← Voltar
                </button>
                <h1>{event.title || 'Detalhes do Evento'}</h1>
            </div>

            <div className="modal-container">
                <EventsModal
                    {...event}
                    setOpen={handleBack}
                />
            </div>
        </div>
    );
}

export default EventsLandingPage;
